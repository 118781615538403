export const environment = {
  production: true,
  isProductionMode: true,
  defaultWebsiteSlug: 'bringpro',
  apiUrl: 'https://dev-api.bringpro.com/',
  captcha_key: '6Lev-eUjAAAAAOMjuMIU37kDX3q6GWmT-NNp-crS',
  google_client_id: "1054376475058-bkinspub1qm7o4m7oqa5dlo2o9ru6v3e.apps.googleusercontent.com",
  apple_client_id: "service.com.bringpro.app",
  legacyApiUrl: 'https://staging.bringpro.com/',
  legacyAppUrl: 'https://s2.bringpro.com/',
  appUrl: "https://dev.bringpro.com/",
  legacyLoginEnabled: true,
  defaultWebsiteId: 55,
  mapKey: "AIzaSyD_6OT7i9lY2pyKDvitJoFgg346I8nZrQM"
  , defaultItemsPerPage: 25,
  firebaseConfig : {
    apiKey: "AIzaSyBoP0Kw7kGqPAu9lLuuGKJ4qEoz3yULUoM",
    authDomain: "dev-bringpro.firebaseapp.com",
    projectId: "dev-bringpro",
    storageBucket: "dev-bringpro.appspot.com",
    messagingSenderId: "621260162314",
    appId: "1:621260162314:web:50993c29e455603c08a6f3",
    measurementId: "G-JPD5E69W83"
  }
};